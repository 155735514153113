import { css } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import Router from 'next/router';
import React from 'react';

import { useModifySearchOpenState } from '@/availability/hooks';
import { availabilitySearchQuerySelectors } from '@/availability/models';
import { BookingStep, useOriginalSearchQuery } from '@/booking-checkout';
import {
  navigateToBookingAdditionalInformationPage,
  navigateToBookingPaymentPage,
} from '@/booking/helpers/url';
import ResponsiveConditional from '@/common/components/responsive/ResponsiveConditional.component';
import { CoreAppEvents } from '@/core/events';
import { useAppEvents } from '@/events';
import { TFunction, useTranslation } from '@/i18n';
import { BackButton } from '@/ui/controls';
import BrandLogo from '@/ui/controls/BrandLogo';
import { Header, HeaderNav } from '@/ui/layout';
import { useTheme } from '@/ui/theme';
import { WizardStep } from '@/ui/wizard';

interface HeaderWithWizardContainerProps {
  bookingId?: string;
  hotelSlug?: string;
  currentPage: BookingStep;
}

const getStepTitle = (step: BookingStep, t: TFunction<'wizard'>): string => {
  switch (step) {
    case BookingStep.SearchForm:
      return t('roomSelectionTitle');
    case BookingStep.RoomSelection:
      return t('roomSelectionTitle');
    case BookingStep.AdditionalInfo:
      return t('additionalInfoTitle');
    case BookingStep.Payment:
      return t('paymentTitle');
    case BookingStep.Confirmation:
      return t('confirmationTitle');
  }
};

export const HeaderWithWizardContainer = observer(
  ({ bookingId, hotelSlug, currentPage }: HeaderWithWizardContainerProps) => {
    const { t } = useTranslation('wizard');
    const events = useAppEvents<CoreAppEvents>();

    const { colors, componentProperties } = useTheme();

    const [, setModifySearchOpen] = useModifySearchOpenState();
    const [getSearchQuery] = useOriginalSearchQuery();

    const currentBookingConfig = bookingId
      ? getSearchQuery(bookingId)
      : undefined;

    const originalQuery = currentBookingConfig
      ? availabilitySearchQuerySelectors(currentBookingConfig).resultsURL()
      : undefined;
    const searchQueryURL = originalQuery || '/availability';

    const pushToAdditionalInformationPage = () => {
      if (!bookingId || !hotelSlug) return;
      return navigateToBookingAdditionalInformationPage({
        hotelSlug,
        bookingId,
      });
    };

    const pushToPaymentPage = () => {
      if (!bookingId || !hotelSlug) return;
      return navigateToBookingPaymentPage({
        hotelSlug,
        bookingId,
      });
    };

    const pushToSearchFormPage = () => {
      return Router.push('/availability');
    };

    const pushToSearchResultsPage = () =>
      Router.push(searchQueryURL, undefined, { shallow: false });

    const emitNavigationEvent = (source: string) =>
      events.emit('navigation', {
        originator: source,
        location: 'Checkout Step Navigation',
      });

    const handleStepSelection = (key: BookingStep) => {
      switch (key) {
        case BookingStep.SearchForm:
          emitNavigationEvent('Select Room');
          return pushToSearchFormPage();
        case BookingStep.RoomSelection:
          emitNavigationEvent('Select Room');
          return pushToSearchResultsPage();
        case BookingStep.AdditionalInfo:
          emitNavigationEvent('Additional Information');
          return pushToAdditionalInformationPage();
        case BookingStep.Payment:
          emitNavigationEvent('Payment');
          return pushToPaymentPage();
        case BookingStep.Confirmation:
          return; // Do nothing
      }
    };

    const handleBackClick = () => {
      switch (currentPage) {
        case BookingStep.SearchForm:
          return; // Do nothing
        case BookingStep.RoomSelection:
          return setModifySearchOpen((state) => !state);
        case BookingStep.AdditionalInfo:
          return pushToSearchResultsPage();
        case BookingStep.Payment:
          return pushToAdditionalInformationPage();
        case BookingStep.Confirmation:
          return; // Do nothing
      }
    };

    const isStillSearching =
      currentPage === BookingStep.SearchForm ||
      currentPage === BookingStep.RoomSelection;

    return (
      <Header
        css={{
          backgroundColor:
            currentPage === BookingStep.SearchForm
              ? componentProperties.searchPage?.header?.backgroundColor
              : undefined,
          ['@media all and (max-width: 730px)']: {
            backgroundColor:
              currentPage === BookingStep.SearchForm
                ? componentProperties.searchPage?.header?.mobile
                    ?.backgroundColor
                : undefined,
          },
        }}
      >
        <ResponsiveConditional
          desktop={
            // Hide if we're on confirmation page. @TODO This is hacky - should be a separate component.
            <HeaderNav
              css={css({
                display:
                  currentPage === BookingStep.Confirmation ? 'none' : 'flex',
              })}
            >
              {currentPage === BookingStep.Confirmation ? (
                <WizardStep
                  title={getStepTitle(BookingStep.Confirmation, t)}
                  onClick={() =>
                    void handleStepSelection(BookingStep.Confirmation)
                  }
                  isActive
                />
              ) : (
                <>
                  <WizardStep
                    stepNumber={1}
                    title={getStepTitle(BookingStep.RoomSelection, t)}
                    onClick={
                      isStillSearching
                        ? undefined
                        : () => handleStepSelection(BookingStep.RoomSelection)
                    }
                    isActive={isStillSearching}
                  />
                  <WizardStep
                    stepNumber={2}
                    title={getStepTitle(BookingStep.AdditionalInfo, t)}
                    onClick={() =>
                      void handleStepSelection(BookingStep.AdditionalInfo)
                    }
                    isActive={currentPage === BookingStep.AdditionalInfo}
                  />
                  <WizardStep
                    stepNumber={3}
                    title={getStepTitle(BookingStep.Payment, t)}
                    onClick={() =>
                      void handleStepSelection(BookingStep.Payment)
                    }
                    isActive={currentPage === BookingStep.Payment}
                  />
                </>
              )}
            </HeaderNav>
          }
          mobile={
            <div
              css={css`
                position: relative;
              `}
            >
              <div
                css={css`
                  position: absolute;
                  height: 40px;
                  /* Align center vertically */
                  top: calc(50% - 18px);
                  left: 14px;
                  color: ${colors.textPrimary ?? '#3d3937'};

                  /* Hides if on the search form (first) or confirmation (last) page in wizard */
                  display: ${currentPage === BookingStep.SearchForm ||
                  currentPage === BookingStep.Confirmation
                    ? 'none'
                    : 'block'};
                `}
              >
                <BackButton onClick={handleBackClick} />
              </div>
              <HeaderNav
                alignContent={
                  currentPage === BookingStep.Confirmation ? 'left' : 'center'
                }
                css={css({
                  backgroundColor:
                    currentPage === BookingStep.SearchForm
                      ? componentProperties.searchPage?.header?.backgroundColor
                      : undefined,
                })}
              >
                {currentPage === BookingStep.Confirmation ? (
                  <BrandLogo />
                ) : (
                  <WizardStep
                    title={getStepTitle(currentPage, t)}
                    isActive={true}
                  />
                )}
              </HeaderNav>
            </div>
          }
        />
      </Header>
    );
  }
);
